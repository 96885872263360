/* Fixes color input styling */

input[type="color"] {
	-webkit-appearance: none;
	border: none;
	width: 24px;
	height: 24px;
	margin-right: 8px;
}
input[type="color"]::-webkit-color-swatch-wrapper {
	padding: 0;
}
input[type="color"]::-webkit-color-swatch {
	border: none;
}

pre code * {
	overflow-wrap: anywhere;
	word-break: break-word;
	white-space: pre-wrap;
}

.remove-image {
	cursor: pointer;
	position: absolute;
	top: -10px;
	right: -10px;
	border-radius: 10em;
	padding: 2px 6px 3px;
	text-decoration: none;
	font: 700 21px/20px sans-serif;
	background: #555;
	border: 3px solid #fff;
	color: #FFF;
	box-shadow: 0 2px 6px rgba(0,0,0,0.5), inset 0 2px 4px rgba(0,0,0,0.3);
	text-shadow: 0 1px 2px rgba(0,0,0,0.5);
	-webkit-transition: background 0.5s;
	transition: background 0.5s;
}
.remove-image:hover {
	background: #E54E4E;
	padding: 3px 7px 5px;
	top: -11px;
	right: -11px;
}
.remove-image:active {
	background: #E54E4E;
	top: -10px;
	right: -11px;
}